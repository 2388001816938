<div mat-dialog-title>
  Lånelöfte
</div>

<div class="holder">
  <form [formGroup]="amountForm">
    <div>
      <mat-form-field>
        <mat-label>Kundtyp</mat-label>
        <mat-select
          formControlName="type"
          ngDefaultControl
          placeholder="Välj i listan">
          <mat-option value="private">Privat</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field>
        <mat-label>Personnummer huvudlåntagare</mat-label>
        <input
          formControlName="applicantId"
          matInput
          type="tel"
          spbPersonnummerValidator>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          Namn huvudlåntagare
        </mat-label>
        <input
          formControlName="applicantFull"
          matInput
        >
      </mat-form-field>

      @if (this.amountForm.controls.coApplicantId.value) {
        <mat-checkbox
          formControlName="coApplicantCheckbox"
          color="primary">Medlåntagare
        </mat-checkbox>
      }
    </div>

    @if (this.amountForm.controls.coApplicantId.value) {
      <div>
        <mat-form-field>
          <mat-label>Personnummer medlåntagare</mat-label>
          <input
            formControlName="coApplicantId"
            matInput
            type="tel"
            spbPersonnummerValidator>
        </mat-form-field>

        <mat-form-field>
          <mat-label>
            Namn medlåntagare
          </mat-label>
          <input
            formControlName="coApplicantFull"
            matInput
          >
        </mat-form-field>
      </div>
    }


    <div>
      <mat-form-field>
        <mat-label>Beviljat datum</mat-label>
        <input formControlName="dateOfIssue"
               matInput>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Förfaller datum</mat-label>
        <input formControlName="validThru"
               matInput>
      </mat-form-field>
    </div>


    <div>
      <div class="input-holder">
        <mat-checkbox
          color="primary"
          id="bostadrattCheckbox"
          ngDefaultControl
          labelPosition="after"
          formControlName="newPropertyType"
        >Bostadsrätt
        </mat-checkbox>
      </div>

      @if (amountForm.controls.newPropertyType.value === 2) {
        <mat-form-field>
          <mat-label>Bostadsrätt max avgift</mat-label>
          <input
            formControlName="newPropertyFee"
            matInput spbFormatNumber>
        </mat-form-field>
      }
    </div>


    <div>
      <mat-form-field>
        <mat-label>Beviljat belopp</mat-label>
        <input formControlName="approvedAmount"
               matInput spbFormatNumber>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Utnyttjat belopp</mat-label>
        <input formControlName="usedAmount"
               matInput spbFormatNumber>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Kvarstående belopp</mat-label>
        <input formControlName="remainingAmount"
               matInput spbFormatNumber>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="message">
        <mat-label>Övrigt</mat-label>
        <textarea
          formControlName="adminInfo"
          matInput
          ngDefaultControl
          rows="3"
        ></textarea>
      </mat-form-field>
    </div>

    <mat-checkbox
      formControlName="fullyUsed"
      ngDefaultControl
      color="primary">Nyttjat till slutfinansiering
    </mat-checkbox>
  </form>
</div>

<div mat-dialog-actions align="end">
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  <button (click)="save()"
          [disabled]="!amountForm.valid" color="primary"
          mat-raised-button>Spara
  </button>
</div>
