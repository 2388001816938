import {AsyncPipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {MatIconRegistry} from '@angular/material/icon'
import {DomSanitizer} from '@angular/platform-browser'
import {Router, RouterOutlet} from '@angular/router'
import {FooterComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter, first} from 'rxjs'
import version from '../assets/package.json'
import {HeaderComponent} from './components/header/header.component'
import {ConfigService, ILogInState} from './services/config.service'

@Component({
  selector: 'spb-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [HeaderComponent, RouterOutlet, AsyncPipe, FooterComponent]
})
export class AppComponent implements OnInit {
  public version = version.version
  public copyrightYears = `2019-${new Date().getFullYear()}`

  constructor(
    public configService: ConfigService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) {
    // Add custom SVG icons to use later in <mat-icon>
    this.matIconRegistry.addSvgIcon(
      'manually_issued',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/manually_issued.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'add_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/add_notes.svg')
    )
    this.matIconRegistry.addSvgIcon(
      'edit_notes',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/edit_notes.svg')
    )
  }

  public ngOnInit() {
    this.configService.logInState$
      .pipe(filter(Boolean), first())
      .subscribe({
        next: (v: ILogInState): void => {
          if ((v.admin && v.registrar) || (v.readOnly && v.registrar)) {
            this.router.navigate(['admin']).then()
          } else if (v.admin) {
            this.router.navigate(['admin', 'classic']).then()
          } else if (v.readOnly) {
            this.router.navigate(['admin', 'classic']).then()
          } else if (v.registrar) {
            this.router.navigate(['admin', 'register']).then()
          } else {
            this.router.navigate(['admin']).then()
          }
        }
      })
  }
}
