<h3 mat-dialog-title>Välj ansvarig</h3>
<mat-dialog-content>
  <mat-form-field [style.margin-top.px]="2">
    <mat-label>Ansvarig</mat-label>
    <mat-select [formControl]="selectDelegate">
      <mat-option value=""></mat-option>

      @for (user of loanService.users$ | async; track $index) {
        <mat-option [value]="user.name">
          {{ user.name }} - {{ user.office }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Kontor</mat-label>
    <mat-select [formControl]="selectOffice">
      <mat-option [value]=""></mat-option>
      @for (office of offices; track $index) {
        <mat-option [value]="office">
          {{ office }}
        </mat-option>
      }

    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
  <button (click)="save()"
          [disabled]="(currentAssigneeValue === selectDelegate.value) && (currentOfficeValue === selectOffice.value)"
          color="primary"
          mat-raised-button>
    Spara
  </button>
</mat-dialog-actions>