import {inject} from '@angular/core'
import {LoanService} from '../services/loan.service'
import {forkJoin} from 'rxjs'

export const applicationResolver = () => {
  const service = inject(LoanService)
  return forkJoin({
    users: service.getUsers(),
    offices: service.getOffices()
  })
}