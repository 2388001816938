import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {Component, OnInit, QueryList, ViewChildren} from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatDialogRef} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {
  MatStep,
  MatStepContent,
  MatStepLabel,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {
  IApplication,
  IApplicationUpdate,
  PropertyType,
  TFuturePropertyOwnership
} from '@sparbanken-syd/loan-backend'
import {
  OldPropertyStatusMap,
  PropertyTypeMap
} from '@sparbanken-syd/loan-backend/dist/shared'
import {filter, first} from 'rxjs'
import {
  loanPropertyPriceValidator
} from '../../../directives/LoanPropertyPriceValidator'
import {LoanService} from '../../../services/loan.service'
import {EditFieldComponent} from './edit-field/edit-field.component'

interface LoanFormGroup {
  amount: FormControl<number>
  type: FormControl<number>
}

interface IOldPropertyGroup {
  futurePropertyOwnership: FormControl<TFuturePropertyOwnership>
  loanAmount: FormControl<number | null>
  price: FormControl<number | null>
  type: FormControl<PropertyType>
  fee: FormControl<number | null>
}

interface EditableApplicationForm {
  income: FormArray<FormControl<number | null>>
  loans: FormArray<FormGroup<LoanFormGroup>>
  oldProperty: FormGroup<IOldPropertyGroup>
  studyLoan: FormControl<number | null>
  price: FormControl<number | null>
  downPayment: FormControl<number | null>
  fee: FormControl<number | null>
}

@Component({
  selector: 'spb-kalp-editing',
  templateUrl: './kalp-editing.component.html',
  styleUrls: ['kalp-editing.component.scss'],
  imports: [MatStepper, MatStep, MatStepLabel, MatStepContent, EditFieldComponent, MatButton, MatStepperNext, MatFormField, MatInput, CdkTextareaAutosize, ReactiveFormsModule, MatStepperPrevious]
})
export class KalpEditingComponent implements OnInit {

  @ViewChildren(EditFieldComponent) fields: QueryList<EditFieldComponent> = [] as any

  public editionForm =
    new FormGroup<EditableApplicationForm>(({
      income: new FormArray<FormControl<number | null>>([]),
      loans: new FormArray(([] as any)),
      oldProperty: new FormGroup(({
        futurePropertyOwnership: new FormControl(),
        loanAmount: new FormControl<number | null>(null),
        price: new FormControl<number | null>(null),
        type: new FormControl(PropertyType.NONE),
        fee: new FormControl(undefined)
      } as IOldPropertyGroup)),
      studyLoan: new FormControl<number | null>(null, [Validators.max(10 * 1000)]),
      price: new FormControl<number | null>(0, [Validators.required, Validators.min(0.1)]),
      downPayment: new FormControl<string | number>(0, [Validators.required, Validators.min(0.1)]),
      fee: new FormControl<string | number>(0)
    } as EditableApplicationForm), loanPropertyPriceValidator)

  public notes: FormControl<string> = new FormControl<string>('', {
    nonNullable: true,
    validators: [Validators.required, Validators.minLength(1)]
  })

  public hasChanges = false

  public propertyStatus: [string, string | number][] = Array.from(OldPropertyStatusMap, ([label, value]) => [value, label])
  public propertyType: [string, string | number][] =
    Array.from(PropertyTypeMap, ([k, v]) => [v, k])

  protected application!: IApplication

  constructor(
    private loanService: LoanService,
    protected dialogRef: MatDialogRef<KalpEditingComponent>
  ) {
  }

  get futureType() {
    return this.editionForm.controls.oldProperty.controls.futurePropertyOwnership.value
  }

  get showPropertyType(): boolean {
    return ['intendToSell', 'intendToSellEditable', 'intendToSellEditableByPrice', 'keepingProperty']
      .indexOf(this.futureType) !== -1
  }

  get showPrice(): boolean {
    return ['intendToSell', 'intendToSellEditable', 'intendToSellEditableByPrice', 'confirmedContract']
      .indexOf(this.futureType) !== -1
  }

  get showLoans(): boolean {
    return ['intendToSell', 'intendToSellEditable', 'intendToSellEditableByPrice', 'confirmedContract', 'keepingProperty']
      .indexOf(this.futureType) !== -1
  }

  get showRentalKeep(): boolean {
    return ['rentalKeep']
      .indexOf(this.futureType) !== -1
  }

  get sellLabel(): string {
    return this.futureType.includes('intendToSell') ? 'Bostadens värde' : 'Försäljningspris'
  }

  public addLoan() {
    // Create a loan with default values
    const group = new FormGroup({
      amount: new FormControl<number>(0, {nonNullable: true}),
      type: new FormControl<number>(-1, {nonNullable: true})
    })

    // Add the new loan group to the form's loans array
    this.editionForm.controls.loans.push(group)

    // Add the value of the new loan (with default values) to the application's loan data
    this.application.loans?.push(group.getRawValue())
  }

  /**
   * Removes the loan entry (by index) from the loans FormArray and the
   * application's loan list.
   */
  public removeLoan(index: number) {
    const loans = this.editionForm.controls.loans

    // Reset the loan's values before removal
    loans.controls[index].setValue({type: -1, amount: 0})

    // Remove the loan group from the FormArray
    loans.removeAt(index)

    // Remove the loan entry from the application's loan data
    this.application.loans?.splice(index, 1)
  }

  public ngOnInit(): void {
    this.loanService.application$
      .pipe(filter(Boolean), first())
      .subscribe((application) => {
        // Make a copy so that we can cancel w/o changing the original
        this.application = JSON.parse(JSON.stringify(application))

        // Create income entries depending on how many applicants there are
        this.application.applicants.forEach(() => {
          const control = new FormControl<number | null>(null, {
            validators: [Validators.required, Validators.min(1)],
            nonNullable: true
          })
          this.editionForm.controls.income.push(control)
        })

        // Create loan entries depending on how many loans there are
        this.application.loans?.forEach(l => {
          const group = new FormGroup({
            amount: new FormControl<number>(l.amount, {nonNullable: true}),
            type: new FormControl<number>(l.type, {nonNullable: true})
          })
          this.editionForm.controls.loans.push(group)
        })
      })

    const lastValue = this.application.oldProperty.futurePropertyOwnership

    this.editionForm.controls.oldProperty.controls.futurePropertyOwnership.valueChanges
      .subscribe((value) => {

        // Don't change anything when switching between intendToSell scenarios...
        if (
          lastValue !== value &&
          lastValue?.includes('intendToSell') && value.includes('intendToSell')) {
          return
        }

        // Reset controls if new value differs from current value
        if (value !== this.application.oldProperty.futurePropertyOwnership) {
          delete this.application.oldProperty.fee
          delete this.application.oldProperty.price
          delete this.application.oldProperty.loanAmount
          this.application.oldProperty.type = PropertyType.NONE
        }

        const controls = this.editionForm.controls.oldProperty.controls

        Object.entries(controls).forEach(([key, control]) => {
          // Only update controls that are NOT 'futurePropertyOwnership'
          if (lastValue !== value && key !== 'futurePropertyOwnership') {
            control.setValue(null)
          }

          control.setValidators(null)
          control.updateValueAndValidity({emitEvent: false})
        })


        if (this.futureType.includes('rental')) {
          this.application.oldProperty.type = PropertyType.HYRES
        }

        if (this.showRentalKeep) {
          controls.fee.addValidators([Validators.required, Validators.min(1)])
        }

        if (this.showPrice) {
          controls.price.addValidators([Validators.required, Validators.min(1)])
        }

        if (this.showLoans) {
          controls.loanAmount.addValidators([Validators.required, Validators.min(1)])
        }

        if (this.showPropertyType) {
          controls.type.addValidators([Validators.required, Validators.min(0)])
        }

        Object.values(controls).forEach((v: AbstractControl) => {
          v.updateValueAndValidity({emitEvent: false})
        })
      })

    // Listen to Old Property type to remove fee when needed
    this.editionForm.controls.oldProperty.controls.type.valueChanges
      .subscribe((value) => {
        this.editionForm.controls.oldProperty.controls.fee.setValidators(null)
        if (value === PropertyType.BOSTADSRATT) {
          this.editionForm.controls.oldProperty.controls.fee.addValidators(
            [Validators.required, Validators.min(1)])
        }
        this.editionForm.controls.oldProperty.controls.fee
          .updateValueAndValidity({emitEvent: false})
      })

    /**
     * Look for real changes
     */
    this.editionForm.valueChanges.subscribe({
      next: () => {
        this.hasChanges = this.fields
          .map(f => f.changes)
          .filter(f => f)
          .length > 0
      }
    })
  }

  public onSaveClick() {
    // Create the update from application's copy, update it and add extra fields
    const applicationUpdate: IApplicationUpdate = {
      notes: this.notes.value,
      changes: this.fields.map(f => f.changes)
        .filter(f => f) as string[]
    }

    // Update application
    this.loanService.updateApplication(this.application, applicationUpdate)
      .subscribe({
        next: () => {
          this.dialogRef.close()
        }
      })
  }
}
