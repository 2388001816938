import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle
} from '@angular/material/dialog'

@Component({
  selector: 'spb-complete-question',
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle
  ],
  templateUrl: './complete-question.component.html',
  styleUrl: './complete-question.component.scss'
})
export class CompleteQuestionComponent {
}
