import {Routes} from '@angular/router'
import {AdminContentComponent} from './admin/classic/admin-content.component'
import {HomeComponent} from './admin/home/home.component'
import {KalpComponent} from './admin/kalp/kalp.component'
import {
  RegisterListComponent
} from './admin/register/register-list/register-list.component'
import {applicationResolver} from './application/application.resolver'
import {authGuard} from './application/auth.guard'

export const routes: Routes = [
  {
    path: 'admin',
    component: HomeComponent
  },
  {
    path: 'admin/classic',
    component: AdminContentComponent,
    resolve: [applicationResolver],
    canActivate: [authGuard]
  },
  {
    path: 'admin/register',
    component: RegisterListComponent,
    resolve: [applicationResolver],
    canActivate: [authGuard]
  },
  {
    path: 'admin/promise/:id',
    component: KalpComponent,
    resolve: [applicationResolver],
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: 'admin',
    pathMatch: 'full'
  }
]