<div class="header">
  <spb-logo routerLink="admin" title="Lånelöfte Admin"></spb-logo>
  <div [style.flex-grow]="1"></div>

  @if (configService.logInState$ | async; as user) {
    <div class="menu-holder">
      <span class="username">{{ user.name }}</span>

      <button [matMenuTriggerFor]="menu" mat-button>
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  }
</div>
<mat-menu #menu="matMenu" class="menu">
  @if (configService.isAdmin$() || configService.isReadOnly$()) {
    <button routerLink="admin/classic" mat-menu-item>Enkla lånelöftet</button>
  }

  @if (configService.isRegistrar$()) {
    <button routerLink="admin/register" mat-menu-item>Registrering och översikt
    </button>
  }
  <button (click)="configService.logout()" mat-menu-item>Logga ut</button>
</mat-menu>