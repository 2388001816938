<div class="status-progress-holder">
  @for (status of progress; track $index) {
    <div class="status-holder">
      <div class="status-info-holder">
        <div class="status-name">
          @for (name of status.name; track $index) {
            {{ name }}
          }
        </div>

        @if (!status.declined && ($index === ProgressStatusIndex.REJECTED) && (status.isActive || isManuallyIssued)) {
          <div class="arrow"></div>

          <div class="status-info">
            @for (error of rejectionReasons | keyvalue: originalOrder; track $index) {
              <div class="status-info-check">
                <mat-icon
                  [class]="error.value.isOk ? 'active' : ''">{{ error.value.isOk ? 'check' : 'close' }}
                </mat-icon>
                <span class="status-info-check">{{ error.value.name }}</span>
              </div>
            }
          </div>
        }
      </div>

      <div
        class="status-bubble"
        [class]="{ active: status.isActive, done: status.isDone}">
        @if ($index === ProgressStatusIndex.APPROVED && status.isActive) {
          <mat-icon class="bubble-icon issued">check</mat-icon>
        } @else if ($index === ProgressStatusIndex.REJECTED && status.isActive) {
          <mat-icon class="bubble-icon rejected">close</mat-icon>
        }
      </div>
    </div>

    <!-- Line -->
    @if (!$last) {
      <div class="separator" [class]="{done: status.isDone}"></div>
    }
  }
</div>