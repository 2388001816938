<h3>UC</h3>

<div class="multi-group-holder">
  @if (!isUcAvailable) {
    <p>Det finns ingen information från UC</p>
  } @else {
    @for (applicant of application.applicants; track $index) {
      @if (applicant.uc) {
        <div class="group-holder">
          <h4>{{ applicant.uc.applicantName }}</h4>

          @if (configService.isAdmin$()) {
            <button
              mat-raised-button (click)="getReport(applicant.personNummer)">
              Hämta
              UC Rapport
            </button>
          }
          <div
            [style.padding-top.px]="10"
            class="subgroup-holder">
            <spb-kalp-item
              [textValue]="true"
              text="Beslut"
              [value]="applicant.uc.decision | ucDecision">

            </spb-kalp-item>
            <spb-kalp-item
              [textValue]="true"
              text="Anledning(ar)"
              [value]=""></spb-kalp-item>

            <ul>
              @for (reason of applicant.uc.reasons; track $index) {
                <li>{{ reason }}</li>
              }
            </ul>

            <spb-kalp-item
              format="1.0-0"
              text="Inkomst (senaste)"
              [value]="applicant.uc.income[0]"></spb-kalp-item>
            <spb-kalp-item
              format="2.0-0"
              text="Länskod"
              [value]="applicant.uc.countyCode"></spb-kalp-item>
            <spb-kalp-item
              format="2.0-0"
              text="Kommunkod"
              [value]="applicant.uc.municipalityCode"></spb-kalp-item>
            <spb-kalp-item
              format="2.0-0"
              text="Församlingskod"
              [value]="applicant.uc.parishCode"></spb-kalp-item>
          </div>
        </div>
      }
    }
  }
</div>
