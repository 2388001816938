import {Component, inject, OnInit} from '@angular/core'
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from '@angular/material/datepicker'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {
  MatError,
  MatFormField,
  MatLabel,
  MatSuffix
} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {
  APPLICATION_ID,
  IRegistration,
  IRegistrationApplicant,
  TApplicationStatus,
  TRegistrationPropertyType,
  TRegistrationType
} from '@sparbanken-syd/loan-backend'
import {
  FormatNumberDirective,
  PersonnummerValidatorDirective
} from '@sparbanken-syd/sparbanken-syd-theme'
import {DateTime} from 'luxon'
import {filter, first, switchMap, tap} from 'rxjs'
import {
  CompleteQuestionComponent
} from '../../../components/complete-question/complete-question.component'
import {ConfigService, ILogInState} from '../../../services/config.service'
import {RegisterService} from '../../../services/register.service'


const UTILITIES = {
  /**
   * Formats a name string: trims, reduces multiple spaces, converts to title case
   * and handles hyphenated names.
   */
  formatName: (name: string): string => {
    return name
      .trim()
      .replace(/\s+/g, ' ')
      .toLowerCase()
      .replace(/(^|\s)\S/g, (c) => c.toUpperCase())
      .replace(/(?:^|-)\S/g, (c) => c.toUpperCase())
  },

  /**
   * Validates that a name contains at least one letter
   */
  hasLettersValidator: (): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value
      if (!value) return null

      const hasLetter = /[a-öA-Ö]/.test(value)
      return !hasLetter ? {onlyNumbers: true} : null
    }
  },

  /**
   * Returns a form group for an applicant with name and sub fields.
   */
  getApplicantForm: () => {
    return new FormGroup({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [
          Validators.required,
          UTILITIES.hasLettersValidator()
        ]
      }),
      sub: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.minLength(1)]
      })
    })
  },

  /**
   * Validates date comparison, ensuring validThru is not earlier than dateOfIssue.
   */
  dateComparisonValidator: (dateOfIssue: Date, validThru: Date) => {
    return (dateOfIssue && validThru && dateOfIssue > validThru) ? {datesNotValid: true} : null
  }
}

@Component({
  selector: 'spb-register-loan-promise',
  templateUrl: './register-loan-promise.component.html',
  styleUrl: './register-loan-promise.component.scss',
  imports: [
    MatDialogTitle, ReactiveFormsModule, MatFormField, MatLabel, MatSelect,
    MatOption, MatInput, MatError, MatCheckbox, MatDatepickerInput,
    MatDatepickerToggle, MatSuffix, MatDatepicker, MatDialogActions,
    MatButton, MatDialogClose, PersonnummerValidatorDirective, FormatNumberDirective
  ]
})
export class RegisterLoanPromiseComponent implements OnInit {
  public coApplicantCheckbox = new FormControl<boolean>(false)
  public isLoading = true
  private dialog = inject(MatDialog)

  /**
   * Main form group for registering a loan promise.
   */
  public registerLoanPromiseForm = new FormGroup({
    [APPLICATION_ID]: new FormControl<string>(''),
    adminData: new FormGroup({
      assignee: new FormControl<string>('', {nonNullable: true}),
      office: new FormControl<string>('', {nonNullable: true}),
      phone: new FormControl<string>('', {nonNullable: true})
    }),

    dateOfIssue: new FormControl<Date | number | null>(new Date(), {
      nonNullable: true,
      validators: (control: AbstractControl) => UTILITIES.dateComparisonValidator(
        control.value,
        this.registerLoanPromiseForm?.controls.validThru.value
      )
    }),
    validThru: new FormControl<Date | number | null>(null, {
      validators: (control: AbstractControl) => UTILITIES.dateComparisonValidator(
        this.registerLoanPromiseForm?.controls.dateOfIssue.value,
        control.value
      )
    }),
    applicants: new FormArray([UTILITIES.getApplicantForm()]),
    property: new FormGroup({
      type: new FormControl<TRegistrationPropertyType | null>(null, {nonNullable: true}),
      maxFee: new FormControl<number | null>(null, {nonNullable: true})
    }),
    approvedAmount: new FormControl<number | null>(null, {
      nonNullable: true,
      validators: [Validators.required, Validators.min(0.1)]
    }),
    type: new FormControl<TRegistrationType | null>(null, {
      nonNullable: true, validators: [Validators.required]
    }),
    remainingAmount: new FormControl<number | null>({
      value: null,
      disabled: true
    }, {nonNullable: true}),
    usedAmount: new FormControl<number>(0, {nonNullable: true}),
    adminInfo: new FormControl<string>('', {nonNullable: true}),
    insurance: new FormControl<string>('', {nonNullable: true}),
    purpose: new FormControl<string>('', {nonNullable: true}),
    limitFee: new FormControl<number | null>(null, {nonNullable: true}),
    confirmed: new FormControl<boolean>(true),
    version: new FormControl<number>(0),
    status: new FormControl<TApplicationStatus | undefined>(undefined),
    fullyUsed: new FormControl<boolean>(false, {nonNullable: true}),
    timeStamp: new FormControl<number | undefined>(undefined)
  })

  public data = inject(MAT_DIALOG_DATA) as string
  private configService = inject(ConfigService)
  private dialogRef = inject(MatDialogRef<RegisterLoanPromiseComponent>)
  private registerService = inject(RegisterService)

  get applicants(): any {
    return this.registerLoanPromiseForm.controls.applicants.controls
  }

  get isPrivate(): boolean {
    return this.registerLoanPromiseForm.controls.type.value === 'private'
  }

  public ngOnInit() {
    this.initializeForm()
    this.setupValueChangesSubscriptions()
  }

  public confirmedChange(event: MatCheckboxChange): void {
    // Set the confirmed value to the opposite of the checkbox (as it is "un-confirmed"
    this.registerLoanPromiseForm.controls.confirmed.setValue(!event.checked)
  }

  /**
   * Initialize the form with existing data or default values
   */
  private initializeForm() {
    if (this.data) {
      this.loadExistingRegistration()
    } else {
      this.addValidThruDate(6)
      this.isLoading = false
    }
  }

  /**
   * Load existing registration data
   */
  private loadExistingRegistration() {
    this.registerService.getRegistered(this.data)
      .pipe(filter(Boolean))
      .subscribe({
        next: (loanPromise: IRegistration) => {
          if (loanPromise.applicants.length > 1) {
            this.coApplicantCheckbox.setValue(true)
          }
          this.registerLoanPromiseForm.patchValue(loanPromise)
          this.registerLoanPromiseForm.controls.dateOfIssue.setValue(new Date(loanPromise.dateOfIssue))
          this.registerLoanPromiseForm.controls.validThru.setValue(new Date(loanPromise.validThru))
          this.isLoading = false
        }
      })
  }

  /**
   * Setup all value changes subscriptions
   */
  private setupValueChangesSubscriptions() {
    this.setupAmountCalculations()
    this.setupTypeChanges()
    this.setupPropertyTypeChanges()
    this.setupCoApplicantChanges()
    this.setupDateOfIssueChanges()
  }

  /**
   * Setup subscriptions related to amount calculations
   */
  private setupAmountCalculations() {
    // Update remaining amount when approvedAmount changes
    this.registerLoanPromiseForm.controls.approvedAmount.valueChanges
      .pipe(filter(Boolean))
      .subscribe((approvedAmount: number) => {
        const confirmedAmount = this.registerLoanPromiseForm.controls.usedAmount.value
        this.registerLoanPromiseForm.controls.remainingAmount.setValue(approvedAmount > 0 ? approvedAmount - (confirmedAmount || 0) : 0)
      })

    // Update remaining amount when usedAmount changes
    this.registerLoanPromiseForm.controls.usedAmount.valueChanges
      .subscribe((confirmedAmount: number) => {
        const approvedAmount = this.registerLoanPromiseForm.controls.approvedAmount.value || 0
        this.registerLoanPromiseForm.controls.remainingAmount.setValue(approvedAmount > 0 ? approvedAmount - confirmedAmount : 0)
      })
  }

  /**
   * Setup subscription for type (company/private) changes
   */
  private setupTypeChanges() {
    this.registerLoanPromiseForm.controls.type.valueChanges
      .subscribe((type: 'company' | 'private' | null) => {
        if (type === 'company') {
          this.registerLoanPromiseForm.controls.purpose.addValidators([Validators.required])
          this.addValidThruDate(12)
        } else {
          this.addValidThruDate(6)
          this.registerLoanPromiseForm.controls.purpose.setValidators(null)
          this.registerLoanPromiseForm.controls.purpose.reset()
        }
        this.registerLoanPromiseForm.controls.purpose.updateValueAndValidity({emitEvent: false})
      })
  }

  /**
   * Setup subscription for property type changes
   */
  private setupPropertyTypeChanges() {
    this.registerLoanPromiseForm.controls.property.controls.type.valueChanges
      .subscribe((propertyType: TRegistrationPropertyType) => {
        if (propertyType === 'bostadsratt') {
          this.registerLoanPromiseForm.controls.property.controls.maxFee.addValidators([
            Validators.required, Validators.min(0.1)
          ])
        } else {
          this.registerLoanPromiseForm.controls.property.controls.maxFee.setValidators(null)
          this.registerLoanPromiseForm.controls.property.controls.maxFee.reset()
        }
        this.registerLoanPromiseForm.controls.property.controls.maxFee.updateValueAndValidity({emitEvent: false})
      })
  }

  /**
   * Setup subscription for co-applicant checkbox changes
   */
  private setupCoApplicantChanges() {
    this.coApplicantCheckbox.valueChanges
      .subscribe((checked: boolean | null) => {
        if (checked) {
          this.registerLoanPromiseForm.controls.applicants.push(UTILITIES.getApplicantForm())
        } else {
          this.registerLoanPromiseForm.controls.applicants.removeAt(1)
        }
      })
  }

  /**
   * Setup subscription for date of issue changes
   */
  private setupDateOfIssueChanges() {
    this.registerLoanPromiseForm.controls.dateOfIssue.valueChanges
      .subscribe(() => {
        this.addValidThruDate(this.isPrivate ? 6 : 12)
      })
  }

  /**
   * Adds a valid 'validThru' date by adding a specified number of months to 'dateOfIssue'.
   * Using Luxon instead of native Date for reliable month transitions
   */
  private addValidThruDate(months: number) {
    // Convert to Luxon DateTime if it's not already a DateTime object
    const dateOfIssue = DateTime.isDateTime(this.registerLoanPromiseForm.controls.dateOfIssue.value)
      ? this.registerLoanPromiseForm.controls.dateOfIssue.value
      : DateTime.fromJSDate(this.registerLoanPromiseForm.controls.dateOfIssue.value)

    // Add months using Luxon plus
    const futureDate = dateOfIssue.plus({months})

    this.registerLoanPromiseForm.controls.validThru.setValue(futureDate)
    this.registerLoanPromiseForm.controls.dateOfIssue.updateValueAndValidity({emitEvent: false})
  }

  /**
   * Adds a valid 'validThru' date by adding a specified number of months to 'dateOfIssue'.
   */


  /**
   * Formats a name using the formatName utility if the form type is 'private'.
   */
  public formatName = (control: FormControl) => {
    if (this.isPrivate) {
      control.setValue(UTILITIES.formatName(control.value))
    }
  }


  public save() {
    if (this.registerLoanPromiseForm.value.fullyUsed) {
      this.dialog.open(CompleteQuestionComponent)
        .afterClosed().pipe(filter(Boolean))
        .subscribe({
          next: () => {
            this.performSave()
          }
        })
    } else {
      this.performSave()
    }
  }

  /**
   * Saves the registration, converts dates to timestamps, and assigns admin data.
   */
  public performSave() {
    const registration = this.registerLoanPromiseForm.value as IRegistration
    registration.dateOfIssue = new Date(registration.dateOfIssue).getTime()
    registration.validThru = new Date(registration.validThru).getTime()

    // Remove hyphens from subs to ensure consistent format.
    // We do it here because we want spbPersonnummerValidator in the UI
    registration.applicants
      .filter((applicant) => !!applicant)
      .forEach((applicant: IRegistrationApplicant) => {
        applicant.sub = applicant.sub.replaceAll('-', '')
      })

    this.configService.logInState$.pipe(
      filter(Boolean),
      first(),
      tap((user: ILogInState) => {
        // Set adminData with current user details,
        // only if no previous assignee or office exists
        if (!registration.adminData.assignee || !registration.adminData.office) {
          registration.adminData = {
            assignee: user.name,
            office: user.office,
            phone: user.phone
          }
        }
      }),
      switchMap(() => this.saveOrUpdateRegistration(registration)),
      switchMap(() => this.registerService.getRegistrations())
    ).subscribe({
      complete: () => this.dialogRef.close()
    })
  }

  /**
   * Handles saving or updating the registration data.
   */
  public saveOrUpdateRegistration = (registrationData: IRegistration) => {
    return registrationData[APPLICATION_ID] ?
      this.registerService.updateRegistered(registrationData) :
      this.registerService.saveRegistered(registrationData)
  }
}