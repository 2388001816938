import {KeyValuePipe} from '@angular/common'
import {Component, inject, OnDestroy, OnInit} from '@angular/core'
import {MatIcon} from '@angular/material/icon'
import {filter, Subscription} from 'rxjs'
import {LoanService} from '../../../services/loan.service'

interface ProgressStatus {
  isDone: boolean
  isActive: boolean
  name: string[]
  declined: boolean
}

/**
 * There are four levels of status
 */
enum ProgressStatusIndex {
  PENDING,
  REJECTED,
  ISSUABLE,
  APPROVED
}

interface RejectionReason {
  isOk: boolean
  name: string
}

type TRejectionReason =
  'uc'
  | 'income'
  | 'occupation'
  | 'kalp'
  | 'oldProperty'
  | 'credits'

@Component({
  selector: 'spb-kalp-status-progress',
  templateUrl: './kalp-status-progress.component.html',
  styleUrls: ['./kalp-status-progress.component.scss'],
  imports: [MatIcon, KeyValuePipe]
})
export class KalpStatusProgressComponent implements OnInit, OnDestroy {

  protected readonly ProgressStatusIndex = ProgressStatusIndex

  public progress: ProgressStatus[] = [
    {
      isDone: false,
      isActive: false,
      name: ['Ansökan', 'inskickad'],
      declined: false
    },
    {
      isDone: false,
      isActive: false,
      name: ['Kontroller', 'genomförda'],
      declined: false
    },
    {
      isDone: false,
      isActive: false,
      name: ['Lånelöfte', 'godkänt'],
      declined: false
    },
    {
      isDone: false,
      isActive: false,
      name: ['Lånelöfte', 'beviljat'],
      declined: false
    }
  ]

  public rejectionReasons: Map<TRejectionReason, RejectionReason> =
    new Map<TRejectionReason, RejectionReason>()

  public isManuallyIssued = false

  private loanService = inject(LoanService)

  private sub$ = new Subscription()

  public ngOnInit(): void {

    this.sub$ = this.loanService.loanPromiseStatus$
      .pipe(filter(Boolean))
      .subscribe(status => {
        // Set isActive flag
        this.progress[ProgressStatusIndex.PENDING].isActive = false // "pending" status is never recovered from BE
        this.progress[ProgressStatusIndex.REJECTED].isActive = !status.canBeIssued && !status.complete
        this.progress[ProgressStatusIndex.ISSUABLE].isActive = status.canBeIssued
        this.progress[ProgressStatusIndex.APPROVED].isActive = status.complete
        this.progress[ProgressStatusIndex.REJECTED].declined = status.declined
        const activeIndex = this.progress.findIndex(s => s.isActive)
        // Set isDone flag
        this.progress.forEach((status, index) => {
          status.isDone = index < activeIndex
        })

        // Set special name for "manually issued loan", whenever the status is "manually_issued"
        if (this.progress[ProgressStatusIndex.APPROVED].isActive && status.completeManually) {
          this.progress[ProgressStatusIndex.APPROVED].name = ['Lånelöfte', 'beviljat', 'manuellt']
          this.isManuallyIssued = true
        }

        this.rejectionReasons =
          new Map<TRejectionReason, RejectionReason>([
            ['uc', {
              name: 'UC',
              isOk: !status.permanentlyDenied && !status.needsUCAboveThreshold && !status.legacyManual
            }],
            ['kalp', {name: 'KALP', isOk: !status.needsBetterKalp}],
            ['income', {name: 'Inkomst', isOk: !status.needsIncomeCheck}],
            ['occupation', {
              name: 'Anställning',
              isOk: !status.needsOccupationCheck
            }],
            ['credits', {
              name: 'Krediter',
              isOk: !status.needsPropertyLoansMatchingUC && !status.needsBlancoLoansThreshold && !status.needsBlancoLoansMatchingUC
            }],
            ['oldProperty', {name: 'Bostad', isOk: !status.needsPropertyCheck}]
          ])
      })
  }

  public ngOnDestroy(): void {
    this.sub$.unsubscribe()
  }

  public originalOrder(): number {
    return 0
  }
}
