{
  "name": "ll-admin-fe",
  "version": "3.0.6-66ed31ce.0",
  "scripts": {
    "ng": "ng",
    "install": "cp ./package.json ./src/assets",
    "start": "ng serve --ssl --host localhost.sparbanken.me",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "lint": "ng lint",
    "style-lint": "stylelint \"src/**/*.scss\" --fix"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.1.7",
    "@angular/cdk": "^19.1.5",
    "@angular/common": "^19.1.7",
    "@angular/compiler": "^19.1.7",
    "@angular/core": "^19.1.7",
    "@angular/forms": "^19.1.7",
    "@angular/material": "^19.1.5",
    "@angular/platform-browser": "^19.1.7",
    "@angular/platform-browser-dynamic": "^19.1.7",
    "@angular/router": "^19.1.7",
    "rxjs": "~7.8.2",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.1.8",
    "@angular/cli": "^19.1.8",
    "@angular/compiler-cli": "^19.1.7",
    "@angular/language-service": "^19.1.7",
    "@angular/material-luxon-adapter": "^19.1.5",
    "@sparbanken-syd/loan-backend": "^4.1.2",
    "@sparbanken-syd/personnummer": "^3.0.1",
    "@sparbanken-syd/sparbanken-syd-bankid": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-qr": "^19.0.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^19.0.3",
    "@sparbanken-syd/user-documents-backend": "^1.1.1",
    "@types/jasmine": "~5.1.7",
    "@types/jasminewd2": "~2.0.13",
    "@types/jmespath": "^0",
    "@types/luxon": "^3",
    "@types/node": "^22",
    "@types/sparbanken-syd-auth-backend": "^2.3.0",
    "angular-eslint": "^19.1.0",
    "eslint": "^9.21.0",
    "jasmine-core": "~5.6.0",
    "jasmine-spec-reporter": "~7.0.0",
    "jmespath": "^0.16.0",
    "karma": "~6.4.4",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.5.0",
    "postcss": "^8.5.3",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.14.1",
    "stylelint-config-standard-scss": "^14.0.0",
    "stylelint-scss": "^6.11.1",
    "typescript": "~5.7.3",
    "typescript-eslint": "^8.25.0"
  }
}
