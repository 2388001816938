"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.propertiesThatShouldNotHaveLoan = exports.propertiesWithDoubleKalpAndApprovedWithoutOldProperty = exports.propertiesWithDoubleKalp = exports.propertiesExcludedFromKalp = exports.propertyTypeMapping = exports.RegistrationTypes = exports.TFuturePropertyOwnership = exports.PropertyType = exports.LoanType = exports.OccupationType = exports.APPLICATION_ID = void 0;
exports.APPLICATION_ID = 'loan-promise-id';
/********************************************************
 * Applicants
 ********************************************************/
var OccupationType;
(function (OccupationType) {
  OccupationType[OccupationType["PERMANENT"] = 0] = "PERMANENT";
  OccupationType[OccupationType["RETIRED"] = 1] = "RETIRED";
  OccupationType[OccupationType["TIME_LIMITED"] = 2] = "TIME_LIMITED";
  OccupationType[OccupationType["HOURLY"] = 3] = "HOURLY";
  OccupationType[OccupationType["OWN_COMPANY"] = 4] = "OWN_COMPANY";
  OccupationType[OccupationType["STUDENT"] = 5] = "STUDENT";
  OccupationType[OccupationType["UNEMPLOYED"] = 6] = "UNEMPLOYED";
})(OccupationType || (exports.OccupationType = OccupationType = {}));
/********************************************************
 * Loans
 ********************************************************/
var LoanType;
(function (LoanType) {
  LoanType[LoanType["NONE"] = -1] = "NONE";
  LoanType[LoanType["HOUSING"] = 0] = "HOUSING";
  LoanType[LoanType["BLANCO"] = 1] = "BLANCO";
  LoanType[LoanType["CARD_CREDIT"] = 2] = "CARD_CREDIT";
})(LoanType || (exports.LoanType = LoanType = {}));
/********************************************************
 * Properties
 ********************************************************/
var PropertyType;
(function (PropertyType) {
  PropertyType[PropertyType["NONE"] = -1] = "NONE";
  PropertyType[PropertyType["VILLA"] = 0] = "VILLA";
  PropertyType[PropertyType["FRITIDSHUS"] = 1] = "FRITIDSHUS";
  PropertyType[PropertyType["BOSTADSRATT"] = 2] = "BOSTADSRATT";
  PropertyType[PropertyType["HYRES"] = 3] = "HYRES";
})(PropertyType || (exports.PropertyType = PropertyType = {}));
/**
 * - Use
 */
exports.TFuturePropertyOwnership = ['noSell', 'confirmedContract', 'intendToSell', 'intendToSellEditable', 'intendToSellEditableByPrice', 'keepingProperty', 'rentalLeave', 'rentalKeep'];
exports.RegistrationTypes = ['company', 'private'];
exports.propertyTypeMapping = {
  'bostadsratt': 'Bostadsrätt'
};
/********************************************************
 * Kalp
 * NOTE: Please read types-intend-to-sell-readme.txt to understand more!
 ********************************************************/
// Properties that are excluded from the KALP process
exports.propertiesExcludedFromKalp = ['confirmedContract'];
// Properties that receive double KALP consideration
exports.propertiesWithDoubleKalp = ['keepingProperty', 'intendToSell'];
// Do a double KALP. If the KALP without old property is positive, the loan promise could be approved
// The calculation for the new property loan is based on its expected selling price.
exports.propertiesWithDoubleKalpAndApprovedWithoutOldProperty = ['intendToSellEditableByPrice'];
// Properties that should not have any loans in the KALP
exports.propertiesThatShouldNotHaveLoan = ['confirmedContract', 'rentalKeep', 'rentalLeave'];
