<div mat-dialog-title>Observera</div>
<div class="dialog-container">

  <div>
    <p class="text">
      Du har markerat "Nyttjat till slutfinansiering". Om du sparar kommer automatisk
      gallring att ske.
    </p>

    <p class="important-notice">
      OBS! Åtgärden kan inte ångras
    </p>

    <p class="text">
      Är du säker på att du vill gå vidare?
    </p>
  </div>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Avbryt</button>
    <button [mat-dialog-close]="true" color="primary" mat-raised-button>
      Ja
    </button>
  </mat-dialog-actions>
</div>