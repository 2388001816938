import {Component, inject} from '@angular/core'
import {
  MatCard,
  MatCardAvatar,
  MatCardContent,
  MatCardHeader,
  MatCardTitle
} from '@angular/material/card'
import {MatIcon} from '@angular/material/icon'
import {RouterLink} from '@angular/router'
import {BankIdComponent} from '@sparbanken-syd/sparbanken-syd-bankid'
import {environment} from '../../../environments/environment'
import {ConfigService} from '../../services/config.service'

@Component({
  selector: 'spb-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  imports: [BankIdComponent, MatCard, MatCardHeader, MatCardAvatar, MatIcon, MatCardTitle, RouterLink, MatCardContent]
})
export class HomeComponent {
  protected readonly environment = environment

  /**
   * The URL BankID shall use.
   */
  protected bankIdUrl = environment.commonServiceUrl

  /**
   * The domain we use for access
   */
  protected domain = environment.domain

  protected configService = inject(ConfigService)
}
