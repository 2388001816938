<div class="spb-holder">
  @if (loanPromiseData) {
    <div class="spb-content-holder">
      <!-- Title and edition buttons -->
      <div class="header">
        <div class="title-holder">
          <button (click)="location.back()" mat-button>
            <mat-icon>keyboard_backspace</mat-icon>
          </button>

          <div [style.margin-right.px]="10">Lånelöfte</div>
          <spb-status-indicator [loanPromise]="loanPromiseData"/>
        </div>

        <!-- Buttons - Only shown if the application is not issued -->

        <div class="header-buttons">
          @if (configService.isAdmin$()) {
            <button color="primary" mat-raised-button (click)="addNote()">
              <mat-icon class="material-symbols-outlined">description</mat-icon>
              Anteckna
            </button>

            <!-- Decline loan - Only visible if it is not already declined -->
            @if (loanPromiseData.status !== 'declined' && loanPromiseData.status !== 'manually_issued' && loanPromiseData.status !== 'approved') {
              <button color="primary" mat-raised-button (click)="declineLoan()">
                <mat-icon>do_not_disturb_on</mat-icon>
                Avslå lånelöfte
              </button>
            }

            <!-- Issue loan - Only visible if approved or rejected -->
            @if (loanPromiseData.status === 'issuable') {
              <button color="primary" mat-raised-button (click)="issueLoan()">
                <mat-icon>request_quote</mat-icon>
                Bevilja lånelöfte
              </button>
            }
            <!-- Re run UC - Only visible if pending -->
            @if (loanPromiseData.status !== 'approved' && loanPromiseData.status !== 'manually_issued' && loanPromiseData.status !== 'declined') {
              <button color="primary" mat-raised-button (click)="reRunUc()">
                <mat-icon>person_search</mat-icon>
                UC Kontroll
              </button>
            }
            <!-- Edit - Only shown if the application is not issued -->
            @if (loanPromiseData.status !== 'approved' && loanPromiseData.status !== 'manually_issued' && loanPromiseData.status !== 'declined') {
              <button color="primary" mat-raised-button
                      (click)="startEditing()">
                <mat-icon>edit</mat-icon>
                Ändra uppgifter
              </button>
            }
          }
          <!-- Loan Document - Only shown if the application is issued and has document -->
          @if ((loanPromiseData.status === 'approved' || loanPromiseData.status === 'manually_issued')) {
            <button color="primary" mat-raised-button (click)="openDocument()">
              <mat-icon>open_in_new</mat-icon>
              Lånelöfte
            </button>
          }
        </div>

      </div>

      <div class="holder">
        <!-- Assigned admin -->
        @if (loanPromiseData.adminData) {
          <p>Tilldelad: {{ loanPromiseData.adminData.assignee }}</p>
        }


        <spb-kalp-non-superfast-info></spb-kalp-non-superfast-info>

        <spb-kalp-status-progress></spb-kalp-status-progress>

        <spb-kalp-logs></spb-kalp-logs>


        <!-- Basic information (Grunduppgifter) -->
        <spb-kalp-basic-info></spb-kalp-basic-info>

        <!-- Debt ratio (Skuldkvot) -->
        <spb-kalp-debt></spb-kalp-debt>

        <!-- New property (Bostaden) -->
        <spb-kalp-new-property></spb-kalp-new-property>

        <!-- Loan costs (Lånekostnader) -->
        <spb-kalp-loan-costs></spb-kalp-loan-costs>

        <!-- Old property (Existerande bostad) -->
        <spb-kalp-old-property></spb-kalp-old-property>

        <!-- Tax reduction (Ränteavdrag) -->
        <spb-kalp-tax-reduction></spb-kalp-tax-reduction>

        <!-- KALP -->
        <spb-kalp-kalp-info></spb-kalp-kalp-info>

        <!-- UC -->
        <spb-kalp-uc-info></spb-kalp-uc-info>
      </div>
    </div>
  }
</div>
