import {AsyncPipe} from '@angular/common'
import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatOption} from '@angular/material/core'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatSelect} from '@angular/material/select'
import {
  APPLICATION_ID,
  IApplication,
  IApplicationAdminData,
  IRegistration
} from '@sparbanken-syd/loan-backend'
import {switchMap} from 'rxjs'
import {LoanService} from '../../../services/loan.service'
import {RegisterService} from '../../../services/register.service'

/**
 * Type guard to check if the loanPromise is a registration.
 */
const isRegistration = (loanPromise: IApplication | IRegistration): loanPromise is IRegistration =>
  loanPromise.status === 'registered'

@Component({
  selector: 'spb-delegate',
  templateUrl: './delegate.component.html',
  styleUrls: ['./delegate.component.scss'],
  imports: [MatDialogTitle, MatDialogContent, MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption, MatDialogActions, MatButton, MatDialogClose, AsyncPipe]
})
export class DelegateComponent implements OnInit {
  /**
   * Input value from callee, set through data
   */
  public currentAssigneeValue: string | undefined
  public currentOfficeValue: string | undefined
  public phone: string | undefined = undefined
  public owner: string | undefined = undefined

  /**
   * Form controls that holds data for delegate and office.
   */
  public selectDelegate = new FormControl<string>('')
  public selectOffice = new FormControl<string>('')
  protected offices: string[] = []

  constructor(
    public dialogRef: MatDialogRef<DelegateComponent>,
    protected loanService: LoanService,
    private registerService: RegisterService,
    @Inject(MAT_DIALOG_DATA) public data: {
      application: IApplication
      offices: string[]
    }
  ) {
  }

  public ngOnInit(): void {
    this.initializeFormControls()
    this.setInitialFormValues()
  }

  /**
   * Sets up form controls for delegate selection and reacts to changes in value.
   */
  private initializeFormControls(): void {
    this.selectDelegate.valueChanges.subscribe((userName: string | null) => {
      this.updateAdminData(userName)
    })
  }

  /**
   * Initializes the office and delegate form values based on the provided application data.
   */
  private setInitialFormValues(): void {
    this.offices = this.data.offices
    this.selectDelegate.setValue(this.data.application.adminData?.assignee ?? '')
    this.selectOffice.setValue(this.data.application.adminData?.office ?? '')
    this.currentAssigneeValue = this.data.application.adminData?.assignee
    this.currentOfficeValue = this.data.application.adminData?.office
  }

  /**
   * Updates the office and phone fields based on the selected delegate's username.
   *
   * @param userName - The selected delegate's username.
   */
  private updateAdminData(userName: string | null): void {

    if (!userName) {
      this.phone = ''
      this.owner = ''
      return
    }

    const user = this.loanService.users$.value.find(user => user.name === userName)

    const office = user?.office ?? null
    this.phone = user?.phone ?? undefined
    this.owner = user?.sId ?? undefined
    this.selectOffice.setValue(office)
  }

  /**
   * Saves the changes by updating the admin data of the application.
   * Depending on the application type, it updates the assignee and closes the dialog.
   */
  public save(): void {
    const newAdminData: IApplicationAdminData = this.createAdminData()

    // Ensure local data is updated before making API calls
    this.data.application.adminData = newAdminData

    if (isRegistration(this.data.application)) {
      this.updateRegisteredApplication(newAdminData)
    } else {
      this.updateLoanApplication(newAdminData)
    }
  }

  /**
   * Creates a new IApplicationAdmin object with updated assignee, office, and phone values.
   * It falls back to existing values if the new values are empty.
   *
   * @returns The updated IApplicationAdmin object.
   */
  private createAdminData(): IApplicationAdminData {
    return {
      assignee: this.selectDelegate.value ?? this.data.application.adminData?.assignee,
      office: this.selectOffice.value ?? this.data.application.adminData?.office,
      phone: this.phone,
      owner: this.owner
    }
  }

  /**
   * Updates the admin data for registered applications.
   *
   * @param newAdminData - The updated admin data.
   */
  private updateRegisteredApplication(newAdminData: IApplicationAdminData): void {
    const application = this.registerService.registeredPromises$.value.find(
      a => a[APPLICATION_ID] === this.data.application[APPLICATION_ID]
    )

    if (application) {
      application.adminData = newAdminData as any
      this.registerService.updateRegistered(application).subscribe({
        next: () => {
          this.dialogRef.close()
        }
      })
    }
  }

  /**
   * Updates the admin data for loan applications and fetches the latest registrations.
   * First updates the assignee, then retrieves the current registrations
   */
  private updateLoanApplication(newAdminData: IApplicationAdminData): void {
    this.loanService.updateAssignee(this.data.application['loan-promise-id'], newAdminData)
      .pipe(
        switchMap(() => this.registerService.getRegistrations())
      )
      .subscribe({
        next: () => {
          this.dialogRef.close()
        }
      })
  }
}
