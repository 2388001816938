import {InjectionToken} from '@angular/core'

export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')

export const excludedOffices = [
  'Administration',
  'Bankledning',
  'Behörigheter',
  'Ekonomi',
  'Förvaltning',
  'HAK',
  'HR',
  'Kredit',
  'Kundstöd',
  'Produkt, Process & IT',
  'Riskenhet',
  'SDC',
  'Spara och Försäkra',
  'Sparbanken Syd',
  'Säkerhet Internservice',
  'Telefonbanken'
]
