import {DecimalPipe} from '@angular/common'
import {Component, Input} from '@angular/core'

@Component({
  selector: 'spb-kalp-item',
  templateUrl: './kalp-item.component.html',
  styleUrls: ['./kalp-item.component.scss'],
  imports: [DecimalPipe]
})
export class KalpItemComponent {

  @Input() text: string | number | undefined = ''
  @Input() sum = false
  @Input() description = ''
  @Input() format = ''
  @Input() suffix = ''
  @Input() textValue = false

  private pValue: string | number = ''

  public get value(): string | number | undefined {
    if (!this.textValue && Number.isNaN(Number.parseInt(this.pValue as string, 10))) {
      return '0'
    }
    return this.pValue
  }

  @Input()
  public set value(value: string | number | undefined) {
    this.pValue = value ?? 0
  }
}
