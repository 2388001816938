<div class="filter-holder">

  <div class="buttons">
    <button
      [style.min-width.px]="180"
      (click)="register()" color="primary" mat-raised-button>
      Registrera lånelöfte/limit
    </button>

    <button mat-raised-button (click)="ngOnInit()">
      Uppdatera listan
    </button>
  </div>
  <div
    class="filter-controls">
    <mat-form-field>
      <mat-label>Kontor</mat-label>
      <mat-select [formControl]="filter.controls.office" ngDefaultControl
                  placeholder="Samtliga">
        <mat-option [value]="null">Samtliga</mat-option>
        @for (office of loanService.offices$(); track $index) {
          <mat-option
            [value]="office">{{ office }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Handläggare</mat-label>
      <mat-select [formControl]="filter.controls.assignee" ngDefaultControl
                  placeholder="Samtliga">
        <mat-option [value]="null">Samtliga</mat-option>
        @for (assignee of loanService.users$ | async; track $index) {
          <mat-option
            [value]="assignee.name">{{ assignee.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Välj kundtyp</mat-label>
      <mat-select [formControl]="filter.controls.registerType" ngDefaultControl
                  placeholder="Samtliga">
        <mat-option [value]="null">Samtliga</mat-option>
        <mat-option value="private">Privat</mat-option>
        <mat-option value="company">Företag</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Välj kanal</mat-label>
      <mat-select [formControl]="filter.controls.status" ngDefaultControl
                  placeholder="Samtliga">
        <mat-option [value]="null">Samtliga</mat-option>
        <mat-option value="registered">EKP</mat-option>
        <mat-option value="approved">Digitalt automatiskt</mat-option>
        <mat-option value="manually_issued">Digitalt manuellt</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Sök kund</mat-label>
      <input [formControl]="filter.controls.text"
             matInput>
    </mat-form-field>

    @if (loanPromiseList().length > 0) {
      <div [style.padding.px]="5">
        <div class="loan-field-container">
          <span class="loan-field-label">
            Beviljat belopp (totalt: {{ loanPromiseList().length }})
          </span>

          <div class="loan-field-content">
            <span
              class="loan-field-value">{{ allApprovedAmount | number:'1.0-0':'fr' }}
              kr
            </span>
          </div>
        </div>
      </div>
    }
  </div>

</div>

<div class="table-container">
  <table [dataSource]="dataSource" aria-label="register-list"
         class="mat-elevation-z8"
         mat-table matSort>

    <ng-container matColumnDef="dateOfIssue">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Beviljat datum</th>
      <td *matCellDef="let loanPromise"
          mat-cell>{{ loanPromise.dateOfIssue | date:'yyyy-MM-dd' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="validThru">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Förfaller datum</th>
      <td *matCellDef="let loanPromise"
          mat-cell>{{ loanPromise.validThru | date:'yyyy-MM-dd' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="applicantFull">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Huvudlåntagare</th>
      <td
        (click)="isRegistration(loanPromise) ? register(loanPromise['loan-promise-id']) : editLoanPromiseValues(loanPromise)"
        (keyup)="isRegistration(loanPromise) ? register(loanPromise['loan-promise-id']) : editLoanPromiseValues(loanPromise)"
        *matCellDef="let loanPromise"
        class="clickable"
        mat-cell
      >
        <span>{{ loanPromise.applicantFull }}</span><br>
        <span class="sub-text">{{ loanPromise.applicantId }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="coApplicantFull">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Medlåntagare</th>
      <td *matCellDef="let loanPromise" mat-cell>
        <span>{{ loanPromise.coApplicantFull }}</span><br>
        <span class="sub-text">{{ loanPromise.coApplicantId }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Kanal</th>
      <td *matCellDef="let loanPromise" class="icon" mat-cell>
        <div class="icons">
          <spb-status-indicator
            [loanPromise]="loanPromise"></spb-status-indicator>

          @if (!isRegistration(loanPromise)) {
            <span
              [class.clickable]="!isRegistration(loanPromise)"
              [routerLink]="!isRegistration(loanPromise) ?
        ['/admin/promise', loanPromise['loan-promise-id']] : null"
              class="material-symbols-outlined">description</span>
          }
        </div>
      </td>

    </ng-container>

    <ng-container matColumnDef="approvedAmount">
      <th *matHeaderCellDef class="header-number" mat-header-cell
          mat-sort-header>
        Beviljat belopp
      </th>
      <td *matCellDef="let loanPromise" class="number" mat-cell>
        @if (loanPromise) {
          <span>{{ loanPromise.approvedAmount | number:'1.0-0':'fr' }}</span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="remainingAmount">
      <th *matHeaderCellDef mat-header-cell mat-sort-header
          class="header-number">
        Kvarstående belopp
      </th>
      <td *matCellDef="let loanPromise" class="number" mat-cell>
        @if (loanPromise) {
          <span>{{ calculateRemainingAmount(loanPromise) | number:'1.0-0':'fr' }}</span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="usedAmount">
      <th *matHeaderCellDef class="header-number" mat-header-cell
          mat-sort-header>
        Utnyttjat belopp
      </th>
      <td *matCellDef="let loanPromise" class="number" mat-cell>
        @if (loanPromise) {
          <span>{{ (loanPromise.usedAmount || 0) | number:'1.0-0':'fr' }}</span>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="office">
      <th *matHeaderCellDef mat-header-cell mat-sort-header>Kontor</th>
      <td *matCellDef="let loanPromise" mat-cell>{{ loanPromise.office }}</td>
    </ng-container>

    <ng-container matColumnDef="assignee">
      <th *matHeaderCellDef mat-header-cell mat-sort-header scope="col">
        Tilldelad
      </th>
      <td *matCellDef="let loanPromise" mat-cell>

        <button
          (click)="delegate(loanPromise)"
          class="custom-button"
          mat-raised-button
        >
          <div class="button-content">
            <div class="assignee">
              {{ loanPromise.adminData?.assignee || (loanPromise.adminData?.office ? 'Tilldela handläggare' : 'Tilldela') }}
            </div>
            <div class="office">
              {{ loanPromise.adminData?.office }}
            </div>
          </div>
        </button>

      </td>
    </ng-container>


    <ng-container matColumnDef="print">
      <th *matHeaderCellDef class="center" mat-header-cell>Skriv ut</th>
      <td *matCellDef="let loanPromise" class="center" mat-cell>
        @if (loanPromise.confirmed !== false) {
          <mat-icon (click)="print(loanPromise)"
                    (keyup)="print(loanPromise)"
                    class="print icon clickable"
          >print
          </mat-icon>
        } @else {
          <mat-icon class="print icon"
                    matTooltip="Obekräftad"
          >print_disabled
          </mat-icon>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="delete">
      <th *matHeaderCellDef class="center" mat-header-cell>Ta bort</th>
      <td
        (click)="remove(loanPromise['loan-promise-id'], loanPromise.applicantFull)"
        (keyup)="remove(loanPromise['loan-promise-id'], loanPromise.applicantFull)"
        *matCellDef="let loanPromise"
        [matTooltip]="'Gallras ' + (loanPromise.timeStamp * 1000 | date: 'yyyy-MM-dd')"
        class="delete icon clickable center"
        mat-cell
      >
        <mat-icon class="delete">delete_forever</mat-icon>
      </td>
    </ng-container>

    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
    <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row></tr>
    <tr *matNoDataRow>
      <td [attr.colspan]="columnsToDisplay.length" class="no-data">
        Inga lånelöften matchar filter...
      </td>
    </tr>
  </table>
</div>
<mat-paginator [pageSizeOptions]="[20, 50, 100]" [pageSize]="20"
               showFirstLastButtons></mat-paginator>
