"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.blancoHighestThreshold = exports.ucLowestThreshold = exports.OldPropertyStatusMap = exports.OccupationTypeMap = exports.PropertyTypeMap = exports.LoanTypeMap = exports.checkIfSuperFastIsPossible = exports.superFastOldProperty = exports.superFastOccupation = void 0;
/**
 * Shared is shared functions that are shared with the FE.
 */
const types_1 = require("./types");
/**
 * Returns false if any applicant has a no superfast occupation
 */
const superFastOccupation = application => {
  return !application.applicants.some(a => ![types_1.OccupationType.PERMANENT, types_1.OccupationType.RETIRED].includes(a.occupation));
};
exports.superFastOccupation = superFastOccupation;
/**
 * Returns false if the old property is intend to sell
 */
const superFastOldProperty = application => {
  if (!application.oldProperty) {
    return true;
  }
  return !application.oldProperty.futurePropertyOwnership?.includes('intendToSell');
};
exports.superFastOldProperty = superFastOldProperty;
/**
 * There are some conditions that have to be fulfilled in order to do a superfast
 * application. First out is occupation and second one is "intend to sell" old property.
 *
 * We expect a sanitized application with the basics in place.
 *
 * Occupations PERMANENT(0) and RETIRED(1) are ok.
 */
const checkIfSuperFastIsPossible = application => {
  let result = (0, exports.superFastOccupation)(application);
  result = result && (0, exports.superFastOldProperty)(application);
  return result;
};
exports.checkIfSuperFastIsPossible = checkIfSuperFastIsPossible;
exports.LoanTypeMap = new Map([[types_1.LoanType.HOUSING, 'Bostad'], [types_1.LoanType.BLANCO, 'Blanco'], [types_1.LoanType.CARD_CREDIT, 'Kortkredit']]);
exports.PropertyTypeMap = new Map([[types_1.PropertyType.VILLA, 'Villa'], [types_1.PropertyType.FRITIDSHUS, 'Fritidshus'], [types_1.PropertyType.BOSTADSRATT, 'Bostadsrätt'], [types_1.PropertyType.HYRES, 'Hyresrätt']]);
exports.OccupationTypeMap = new Map([[types_1.OccupationType.PERMANENT, 'Tillsvidare'], [types_1.OccupationType.TIME_LIMITED, 'Visstid'], [types_1.OccupationType.HOURLY, 'Timanställd'], [types_1.OccupationType.OWN_COMPANY, 'Egen företagare'], [types_1.OccupationType.STUDENT, 'Student'], [types_1.OccupationType.RETIRED, 'Pensionär'], [types_1.OccupationType.UNEMPLOYED, 'Arbetssökande']]);
exports.OldPropertyStatusMap = new Map([['noSell', 'Jag har inget eget boende idag'], ['confirmedContract', 'Jag har ett ovillkorat säljkontrakt på mitt befintliga boende'], ['intendToSell', 'Jag avser att sälja mitt befintliga boende, men har inget ovillkorat säljkontrakt ännu'], ['intendToSellEditable', 'Handläggare valt - räkna på dubbelt boende och egen insats'], ['intendToSellEditableByPrice', 'Handläggare valt - räkna på dubbelt boende och tänkt pris'], ['keepingProperty', 'Jag äger ett boende som jag avser att behålla'], ['rentalLeave', 'Jag bor i en hyresrätt som jag inte avser att behålla'], ['rentalKeep', 'Jag bor i en hyresrätt som jag avser att behålla']]);
/**
 * Constants for uc thresholds
 */
exports.ucLowestThreshold = 180000;
exports.blancoHighestThreshold = 250000;
