import {DatePipe, DecimalPipe, NgTemplateOutlet} from '@angular/common'
import {
  AfterViewInit,
  Component,
  effect,
  Input,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core'
import {FormControl, ReactiveFormsModule} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatFormField} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatPaginator} from '@angular/material/paginator'
import {MatSort, MatSortHeader} from '@angular/material/sort'
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatNoDataRow,
  MatRow, MatRowDef,
  MatTable,
  MatTableDataSource
} from '@angular/material/table'
import {MatTooltip} from '@angular/material/tooltip'
import {RouterLink} from '@angular/router'
import {IApplicationSummary} from '@sparbanken-syd/loan-backend'
import {
  PersonnummerValidatorDirective
} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter, switchMap} from 'rxjs'
import {startWith} from 'rxjs/operators'
import {
  StatusIndicatorComponent
} from '../../../components/status-indicator/status-indicator.component'
import {ConfigService} from '../../../services/config.service'
import {LoanService} from '../../../services/loan.service'
import {UserDocumentsService} from '../../../services/user-documents.service'
import {TViewType} from '../admin-content.component'
import {DelegateComponent} from '../delegate/delegate.component'
import {
  FindDocumentsComponent
} from '../find-documents/find-documents.component'
import {
  PromiseListDeleteDialogComponent
} from '../promise-list-delete-dialog/promise-list-delete-dialog.component'
import {
  ShowDocumentsComponent
} from '../show-documents/show-documents.component'

@Component({
  selector: 'spb-promise-list',
  templateUrl: './promise-list.component.html',
  styleUrls: ['./promise-list.component.scss'],
  imports: [MatButton, MatFormField, MatInput, ReactiveFormsModule, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, MatCellDef, MatCell, RouterLink, StatusIndicatorComponent, MatTooltip, MatIcon, NgTemplateOutlet, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatNoDataRow, MatPaginator, DecimalPipe, DatePipe, PersonnummerValidatorDirective]
})
export class PromiseListComponent implements OnInit, OnChanges, AfterViewInit {

  public columnsToDisplay = ['dateOfIssue', 'applicantName', 'status', 'approvedAmount', 'kalp', 'assignee', 'delete']

  public pkg: string[] = []

  public loanPromiseList: MatTableDataSource<IApplicationSummary> = new MatTableDataSource<IApplicationSummary>([])

  @Input({required: true}) view: TViewType = 'list'

  /**
   * The paginator to inject into the data source
   */
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator | undefined
  @ViewChild(MatSort, {static: true}) sort: MatSort | undefined

  private loanPromises: IApplicationSummary[] = []

  public filterCtrl = new FormControl<string>('', {nonNullable: true})
  public readonlySub = new FormControl<string>('', {nonNullable: true})

  constructor(
    protected configService: ConfigService,
    public dialog: MatDialog,
    private loanService: LoanService,
    private userDocumentsService: UserDocumentsService
  ) {

    effect(() => {
      // Need to update when an admin reloads the page for some reason
      if (this.configService.isAdmin$()) {
        this.update()
      }
    })
  }

  public ngOnInit() {
    this.filterCtrl.valueChanges.pipe(
      startWith('')
    ).subscribe({
      next: (v: string) => {
        this.loanPromiseList.filter = v.trim().toLowerCase()
      }
    })
    if (this.configService.isAdmin$()) {
      this.update()
    }
  }

  public getReadOnly() {
    this.loanService.listReadOnly(this.readonlySub.value).subscribe({
      next: value => {
        this.columnsToDisplay = ['dateOfIssue', 'applicantName', 'status', 'approvedAmount', 'kalp', 'assignee']
        this.loanPromises = value
        this.loanPromiseList.data = value
        this.showDocumentColumn(value)
      }
    })
  }

  public ngOnChanges() {
    if (this.view === 'register') {
      this.columnsToDisplay = ['dateOfIssue', 'timeOfIssue',
        'applicantFull', 'coApplicantFull', 'kalp', 'approvedAmountFull']
      this.loanPromiseList.data = this.loanPromises.filter(item =>
        item.status === 'approved' || item.status === 'manually_issued')
    } else if (this.view === 'contact') {
      this.columnsToDisplay = ['dateOfIssue', 'timeOfIssue', 'applicantFull', 'applicantContact', 'kalp']
      this.loanPromiseList.data = this.loanPromises
    } else {
      // Full list
      this.columnsToDisplay = ['dateOfIssue', 'applicantName', 'status', 'approvedAmount', 'kalp', 'assignee', 'delete']
      if (this.configService.isUserDoc$()) {
        this.columnsToDisplay = ['dateOfIssue', 'applicantName', 'status', 'approvedAmount', 'kalp', 'assignee', 'delete', 'documents']
      }
      this.loanPromiseList.data = this.loanPromises
    }
  }

  public update(): void {
    this.loanService.list()
      .subscribe((loanPromises: IApplicationSummary[]) => {
        this.loanPromises = loanPromises
        this.loanPromiseList.data = loanPromises

        this.showDocumentColumn(loanPromises)

        // Call ngOnChanges manually to force a view update after a reload
        this.ngOnChanges()
      })
  }

  public checkLoanPromise(loanPromise: IApplicationSummary) {
    return {
      applicant: this.pkg.indexOf(loanPromise.applicantPersonNummer) !== -1,
      coApplicant: this.pkg.indexOf(loanPromise.coApplicantId) !== -1
    }
  }

  public findPkg() {
    if (this.configService.isAdmin$()) {
      this.dialog.open(FindDocumentsComponent, {})
    }
  }


  public ngAfterViewInit(): void {
    this.loanPromiseList.sort = this.sort as MatSort
    this.loanPromiseList.paginator = this.paginator as MatPaginator
  }

  public remove(id: string, name: string): void {
    const dialogRef = this.dialog.open(PromiseListDeleteDialogComponent, {
      data: {name, promiseId: id}
    })

    dialogRef.afterClosed()
      .pipe(
        filter(Boolean),
        switchMap((res: any) => this.loanService.delete(res))
      )
      .subscribe({
        next: () => this.update()
      })
  }

  public getDoc(sub: string) {
    this.userDocumentsService.getPackage(sub).subscribe({
      next: document => {
        this.dialog.open(ShowDocumentsComponent, {
          data: {
            documents: document.savedDocuments,
            sub
          }
        })
      }
    })
  }

  public delegate(application: IApplicationSummary): void {
    this.dialog.open(DelegateComponent, {
      data: {
        application,
        offices: this.loanService.offices$()
      },
      disableClose: true,
      width: '400px'
    })
  }

  private showDocumentColumn(loanPromises: IApplicationSummary[]) {
    const subs = new Set<string>()
    // This will send occasional nulls and undefined but that
    // should be handled by the backend.
    loanPromises.forEach(l => {
      subs.add(l.applicantPersonNummer)
      subs.add(l.coApplicantId)
    })

    if (this.configService.isUserDoc$()) {
      if (this.view === 'list' && !this.columnsToDisplay.includes('documents')) {
        this.columnsToDisplay.push('documents')
      }

      this.userDocumentsService.getAllPackages([...subs])
        .pipe(filter(Boolean))
        .subscribe({
          next: pkg => {
            this.pkg = pkg
          }
        })
    }
  }
}
