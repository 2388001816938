import {Component, inject, OnInit} from '@angular/core'
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms'
import {MatOption} from '@angular/material/autocomplete'
import {MatButton} from '@angular/material/button'
import {MatCheckbox} from '@angular/material/checkbox'
import {
  MAT_DIALOG_DATA, MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {APPLICATION_ID} from '@sparbanken-syd/loan-backend'
import {
  FormatNumberDirective,
  PersonnummerValidatorDirective
} from '@sparbanken-syd/sparbanken-syd-theme'
import {filter, merge, switchMap} from 'rxjs'
import {
  CompleteQuestionComponent
} from '../../../components/complete-question/complete-question.component'
import {RegisterService} from '../../../services/register.service'
import {IRegistrationListItem} from '../register-list/register-list.component'

@Component({
  selector: 'spb-edit-classic',
  imports: [
    MatFormField,
    ReactiveFormsModule,
    MatInput,
    FormatNumberDirective,
    MatLabel,
    MatDialogTitle,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    PersonnummerValidatorDirective,
    MatCheckbox,
    MatOption,
    MatSelect
  ],
  templateUrl: './edit-classic.component.html',
  styleUrl: './edit-classic.component.scss'
})
export class EditClassicComponent implements OnInit {
  public amountForm = new FormGroup({
    type: new FormControl<string>({value: 'Privat', disabled: true}),
    applicantId: new FormControl<string>(''),
    applicantFull: new FormControl<string>({value: '', disabled: true}),
    coApplicantId: new FormControl<string>({value: '', disabled: true}),
    coApplicantFull: new FormControl<string>({value: '', disabled: true}),
    adminInfo: new FormControl<string | undefined>(undefined, {nonNullable: true}),
    newPropertyType: new FormControl<number | undefined>({
      value: undefined,
      disabled: true
    }),
    newPropertyFee: new FormControl<number | undefined>({
      value: undefined,
      disabled: true
    }),
    coApplicantCheckbox: new FormControl<boolean>({
      value: true,
      disabled: true
    }),
    dateOfIssue: new FormControl<string | number | undefined>({
      value: undefined,
      disabled: true
    }),
    validThru: new FormControl<string | number | undefined>({
      value: undefined,
      disabled: true
    }),
    approvedAmount: new FormControl<number | null>({
      value: null,
      disabled: true
    }, {nonNullable: true}),
    remainingAmount: new FormControl<number | null>({
      value: null,
      disabled: true
    }, {nonNullable: true}),
    usedAmount: new FormControl<number | undefined>(undefined, {nonNullable: true}),
    fullyUsed: new FormControl<boolean>(false, {nonNullable: true}),
    [APPLICATION_ID]: new FormControl<string>('')
  })

  public data = inject(MAT_DIALOG_DATA) as IRegistrationListItem

  private registerService = inject(RegisterService)
  private dialogRef = inject(MatDialogRef<EditClassicComponent>)
  private dialog = inject(MatDialog)

  public ngOnInit(): void {
    const {
      approvedAmount,
      usedAmount,
      remainingAmount
    } = this.amountForm.controls

    // Combine both value changes
    merge(
      approvedAmount.valueChanges,
      usedAmount.valueChanges
    ).subscribe(() => {
      const approved = approvedAmount.value || 0
      const used = usedAmount.value || 0
      remainingAmount.setValue(approved - used)
    })
    // Initialize form with data
    this.amountForm.patchValue(this.data)

    // The dates need to be correctly formated (YYYY-MM-DD)
    this.amountForm.controls.dateOfIssue.setValue(new Date(this.data.dateOfIssue).toLocaleDateString('sv-SE'))
    this.amountForm.controls.validThru.setValue(new Date(this.data.validThru).toLocaleDateString('sv-SE'))

    // Disable after patch to allow spbPersonnummerValidator to run
    setTimeout(() => {
      this.amountForm.controls.applicantId.disable()
      this.amountForm.controls.coApplicantId.disable()
    }, 1)
  }

  public save() {
    if (this.amountForm.value.fullyUsed) {
      this.dialog.open(CompleteQuestionComponent)
        .afterClosed().pipe(filter(Boolean))
        .subscribe({
          next: () => {
            this.performSave()
          }
        })
    } else {
      this.performSave()
    }
  }

  private performSave() {
    const updateValues = {
      usedAmount: this.amountForm.value.usedAmount,
      adminInfo: this.amountForm.value.adminInfo,
      fullyUsed: this.amountForm.value.fullyUsed
    }
    this.registerService.updateValues(this.data['loan-promise-id'], updateValues)
      .pipe(
        switchMap(() => this.registerService.getRegistrations())
      )
      .subscribe({
        complete: () => this.dialogRef.close()
      })
  }
}
